
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import utils from "@/stuff/Utils";
//import { Watch } from "vue-property-decorator";

@Component({})
export default class DatePicker extends Vue {
    // the actual date
    @Prop({ default: null })
    private value!: Date | null; // name of "value" needed for v-model

    // boostrap-vue validation style
    @Prop({ default: null })
    private state!: boolean | null;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private compact!: boolean;

    masks: any = {
        input: "DD/MM/YYYY", // <- client requested format
    };

    mounted() {
        if (this.value !== null && !utils.hasDateValue(this.value)) this.$emit("input", null);
    }

    // @Watch("value")
    // onValueChanged(x: Date | null) {
    //     console.log("@@@@@ changed value = ", this.value);
    //     console.log("@@@@@ changed value = ", x);
    // }

    updateValue(e: Date | null) {
        // firing input event updates v-model - don't update the 'prop' directly
        this.$emit("input", utils.hasDateValue(e) ? e : null);
    }

    reset() {
        // firing input event updates v-model - don't update the 'prop' directly
        this.$emit("input", null);
    }
}
