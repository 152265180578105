
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import { ILookupItem } from "@/model/LookupItem";
    import { IUser } from "@/model/User";
    import { IReportParameter, ReportParameter } from "@/model/ReportParameter";
    import { Authentication } from "@/stuff/Authentication";
    import apiClient from "@/stuff/ApiClient";
    import { ButtonToggler } from "@/stuff/ButtonToggler";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";

    @Component({
        components: { ApiButton, DatePicker }
    })
    export default class Reports extends Vue {
        
        async mounted() {
            const isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1
            const user = (Authentication.signedInUser() as IUser)
            this.buyerID = isBuyerZone ? user.buyerID : 0
            await this.getAvailableReports();
        }
        
        // properties

        itemsPerPage: number = 20;
        currentPage: number = 1;

        buyerID: number = 0;
        reports: Array<ILookupItem> = [];

        reportID: number = 0;
        parameters: Array<ReportParameter> = [];

        reportData: Array<any> = [];

        // computed properties

        get canRunReport(): boolean {
            return !utils.isEmptyId(this.reportID) && this.parameters.filter(p => !p.isValid).length == 0;
        }

        // watches

        @Watch("reportID")
        async onReportSelected() {
            this.reportData = [];
            await this.getReportParameters();
        }

        // methods

        private async getAvailableReports() {
            const response = await apiClient.get(`/api/report/availableReports?buyerID=${this.buyerID}`);
            this.reports = utils.selectOptions(response);
        }

        private async getReportParameters() {
            this.parameters = [];
            if (utils.isEmptyId(this.reportID)) return;

            const response: Array<IReportParameter> = await apiClient.get(`api/report/parameters/${this.reportID}`);            
            this.parameters.push(...response.map(r => new ReportParameter(r)));

            // if (this.parameters.length == 0) {
            //     await this.runReport();
            // }
        }

        async runReport(event: Event) {
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button); 

            this.reportData = [];

            const response = await apiClient.post(`/api/report/runReport?reportID=${this.reportID}&buyerID=${this.buyerID}`, this.parameters);
            this.currentPage = 1;
            this.reportData.push(...response);
            
            ButtonToggler.enableButton(button);
        }

        async downloadReport(event: Event) {
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button);

            if (utils.isEmptyId(this.reportID)) {
                toastr.warning("Please select a report", "Cannot download");
                ButtonToggler.enableButton(button);
                return;
            }

            const blob: Blob = await apiClient.post(`api/report/downloadReport?reportID=${this.reportID}&buyerID=${this.buyerID}`, this.parameters);
            utils.downloadBlob(document, blob, "Report.csv");
            ButtonToggler.enableButton(button);
        }

    }
