import { ReportParameterType } from "@/model/Enums";
import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";
import { ILookupItem, LookupItem } from "./LookupItem";

export interface IReportParameter {
    reportID: number;
    parameter: string;
    type: ReportParameterType;
    label: string;
    required: boolean;
    fromDateValue: Date;
    toDateValue: Date;
    boolValue: boolean;    
    dropdownValue: number;
    dropdownOptions: Array<ILookupItem>;
}

export class ReportParameter implements IReportParameter {

    constructor(data?: IReportParameter) {
        if (data) this.update(data);
    }

    update(data: IReportParameter) {
        mapData(data, { 
            root: () => this
        });
    }


    reportID: number = 0;
    parameter: string = "";
    type: ReportParameterType = 0;
    label: string = "";
    required: boolean = false;
    dropdownOptions: Array<LookupItem> = [];

    fromDateValue: Date = new Date(utils.emptyDateValue);
    toDateValue: Date = new Date(utils.emptyDateValue);
    boolValue: boolean = false;
    dropdownValue: number = 0;

    get hasValue(): boolean {
        switch(this.type) {
            case ReportParameterType.DateRange:
                return utils.hasDateValue(this.fromDateValue) && utils.hasDateValue(this.toDateValue);
            case ReportParameterType.Switch:
                return true;
            case ReportParameterType.Dropdown:
                return !utils.isEmptyId(this.dropdownValue);
            default:
                return false;
        }
    }

    get isValid(): boolean {
        if (!this.required && !this.hasValue) return true;
        if (this.required && !this.hasValue) return false;

        switch(this.type) {
            case ReportParameterType.DateRange:
                return this.fromDateValue < this.toDateValue;
            case ReportParameterType.Switch:
                return true;
            case ReportParameterType.Dropdown:
                return !utils.isEmptyId(this.dropdownValue);
            default:
                return false;
        }
    }
}